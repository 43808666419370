<template>
  <!-- Brand logo-->
  <b-link
    to="/"
    class="brand-logo"
  >
    <logo-img />
  </b-link>
  <!-- /Brand logo-->
</template>

<script>
import { BLink } from 'bootstrap-vue'
import LogoImg from '@/views/modules/core/partials/logo/LogoImg.vue'

export default {
  name: 'PortalLogo',
  components: {
    LogoImg,
    BLink,
  },
}
</script>

<style scoped>

</style>
